





















































import { Vue, Prop, Component } from 'vue-property-decorator';
import Store from '@/model/store';
@Component
export default class NaContactUs extends Vue {
    private storeInfo: Store = new Store();
    wRh:number = 2;
    created () {
      let lang = this.$Storage.get('locale');
      this.$Api.seoApi.getStoreData({ Lang: lang }).then((result) => {
        this.storeInfo = result.Store;
      });
    }
}
