
























import { Component, Prop, Vue } from 'vue-property-decorator';
import api from '@/sdk/api/Api.ts';
@Component({
  components: {
    HomeBanner: () => import(/* webpackChunkName: "home" */ '@/components/base/mobile/InsBanner.vue'),
    HotProduct: () => import(/* webpackChunkName: "home" */ '@/components/pkBusiness/mobile/home/PkHotProduct.vue'),
    Cate: () => import(/* webpackChunkName: "home" */ '@/components/NaBusiness/mobile/home/NaCatePromotion.vue'),
    NaPromotion: () => import(/* webpackChunkName: "home" */ '@/components/NaBusiness/mobile/home/NaPromotion.vue'),
    NaContactUs: () => import(/* webpackChunkName: "home" */ '@/components/NaBusiness/mobile/home/NaContactUs.vue')
    // EdCatalogsPromotion: () => import('@/components/edBusiness/mobile/home/EdCatalogsPromotion.vue'),
    // EdProductPromotion: () => import('@/components/edBusiness/mobile/home/EdProductPromotion.vue'),
    // EdMonterssori: () => import('@/components/edBusiness/mobile/home/EdMonterssori.vue')
  }
})
export default class InsHome extends Vue {
  // content: string =
  //   "<p style='text-align: justify;'><span style='font-size: 18px;'>泰美科技為不同行業提供優質可靠的網上平台及系統建立服務，包括手機版網站、 網上商店系統、 網上貿易系統、 合法電郵群發系統、 網上收款系統、 互聯網推廣及 寄存服務等。泰美科技成立超過19年，開業至今已為50個不同行業，超過6,000間大中小型企業、跨國公司、政府部門及慈善機構提供優質網上服務。</span><br/></p>";

  mounted () {
    api
      .getData(1, 1)
      .then((res: any) => {
        var _this = this;
        this.$HiddenLayer();
      })
      .catch((err: any) => {
        // 请求失败后的处理函数
        console.log(err);
      });
  }
}
