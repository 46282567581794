










import { Vue, Prop, Component } from 'vue-property-decorator';
import { BannerResult } from '@/model/bannerResult';
@Component
export default class NaPromotion extends Vue {
    @Prop() page!:string;
    @Prop() position!:string;
    wRh:number = 4;
    private bannerList:BannerResult[] = [];
    created () {
      if (!this.page || !this.position) throw new Error('NewAcePromotion组件：你没有传page 和 position 我拿什么promotion？');
      this.$Api.promotion.getPromotion(this.page, this.position).then((result) => {
        this.bannerList = result.Promotion.BannerList;
        if (this.bannerList.length > 0) {
          let img = new Image();
          let that = this;
          img.src = this.bannerList[0].Image;
          img.onload = (e) => {
            that.wRh = (e.target as any).width / (e.target as any).height;
          };
        }
      });
    }
}
