












import { Component, Prop, Vue } from 'vue-property-decorator';
import api from '@/sdk/api/Api.ts';
@Component({
  components: {
    HomeBanner: () => import(/* webpackChunkName: "home" */ '@/components/base/pc/InsBanner.vue'),
    FsProductPromotion: () => import(/* webpackChunkName: "home" */ '@/components/fsBusiness/pc/home/FsProductPromotion.vue'),
    NaPromotion: () => import(/* webpackChunkName: "home" */ '@/components/NaBusiness/pc/home/NaPromotion.vue'),
    NaCatePromotion: () => import(/* webpackChunkName: "home" */ '@/components/NaBusiness/pc/home/NaCatePromotion.vue'),
    NaContactUs: () => import(/* webpackChunkName: "home" */ '@/components/NaBusiness/pc/home/NaContactUs.vue'),
    NaAddress: () => import(/* webpackChunkName: "home" */ '@/components/NaBusiness/pc/home/NaAddress.vue')
  }
})
export default class InsHome extends Vue {
  created () {
    setTimeout(() => {
      document.dispatchEvent(new Event('render-event'));
    }, 10000);
  }
  mounted () {
    // api
    //   .getData(1, 1)
    //   .then((res: any) => {
    //     var _this = this;
    //     this.$HiddenLayer();
    //   })
    //   .catch((err: any) => {
    //     // 请求失败后的处理函数
    //     console.log(err);
    //   });
  }
}
