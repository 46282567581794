










import { Vue, Prop, Component } from 'vue-property-decorator';
import { BannerResult } from '@/model/bannerResult';
@Component
export default class NaPromotion extends Vue {
    @Prop() page!:string;
    @Prop() position!:string;
    private bannerList:BannerResult[] = [];
    created () {
      if (!this.page || !this.position) throw new Error('NewAcePromotion组件：你没有传page 和 position 我拿什么promotion？');
      this.$Api.promotion.getPromotion(this.page, this.position).then((result) => {
        this.bannerList = result.Promotion.BannerList;
      });
    }
}
