



































import { Vue, Prop, Component } from 'vue-property-decorator';
import Store from '@/model/store';
@Component
export default class NaContactUs extends Vue {
  private storeInfo: Store = new Store();
  created () {
    let lang = this.$Storage.get('locale');
    this.$Api.seoApi.getStoreData({ Lang: lang }).then((result) => {
      this.storeInfo = result.Store;
    });
  }
  mounted () {
    setTimeout(() => {
      (document.getElementById('googleMap') as any).src = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14767.246572014565!2d114.154181!3d22.2851238!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf87762959597fd0a!2z6Kqg6IiI5ZWG5qWt5aSn5buI!5e0!3m2!1szh-TW!2shk!4v1590045885958!5m2!1szh-TW!2shk';
    });
  }
}
