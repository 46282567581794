



















import { Vue, Prop, Component } from 'vue-property-decorator';
import Currency from '@/model/currency';
@Component
export default class PkHotProduct extends Vue {
    Currency = Currency;
    hotProducts:any[]=[];
    bannerImg: string = '';
    swiperOption: object = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      scrollbar: {
        el: '.swiper-scrollbar'
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      slidesPerView: 1.2
    };
    name :string = '';
    @Prop() page!:string;
    @Prop() position!:string;
    loadHotProducts () {
      if (!this.page || !this.position) throw new Error('你没有传page 和 position 我拿什么promotion？');
      var page = 'Home';
      this.$Api.promotion.getPromotion(this.page, this.position).then((result) => {
        this.name = result.Promotion.Name;
        if (result.Promotion.PrmtProductList.length > 0) {
          this.hotProducts = result.Promotion.PrmtProductList;
        }
      });
    }
    mounted () {
      this.loadHotProducts();
    }
}
