


















import { Vue, Prop, Component } from 'vue-property-decorator';
// import InsProductItem from '@/components/business/pc/product/InsProductItem.vue';
import { Promotion } from '@/model/promotionResult';
@Component({ components: { InsProductItem: () => import(/* webpackChunkName: "product" */ '@/components/business/pc/product/InsProductItem.vue') } })
export default class EdProductPromotion extends Vue {
    private promotion: Promotion = new Promotion();
    @Prop({ default: 4 }) column!:number;
    @Prop({ default: 100 }) w!:number;
    @Prop({ default: true }) border!:boolean;
    @Prop({ default: true }) top!:boolean;
    @Prop({ default: true }) button!:boolean;
    @Prop() page!:string;
    @Prop() position!:number;
    created () {
      if (!this.page) throw new Error('推廣頁面不可爲空');
      if (!this.position) throw new Error('推廣位置不可爲空');
      if (window['positionhome1']) {
        window['positionhome1'].then((response) => {
          this.promotion = response;
        });
        return;
      }
      this.$Api.promotion.getPromotion(this.page, this.position).then((result) => {
        this.promotion = result.Promotion;
      });
    }
       private swiperOption = {
         preventClicks: false,
         autoplay: 0,
         autoplayDisableOnInteraction: false,
         slidesPerGroup: this.column,
         slidesPerView: this.column,
         spaceBetween: 20,
         navigation: {
           nextEl: '.swiper-button-next-CertificateID',
           prevEl: '.swiper-button-prev-CertificateID'
         }
       };
       get vww () {
         return window.innerWidth;
       }
}
