
















import { Vue, Prop, Component } from 'vue-property-decorator';
@Component
export default class NaAddress extends Vue {
    private show = false;
    created () {
      setTimeout(() => { this.show = true; }, 5000);
    }
}
